import React from 'react';
import './NavItem.css';
import DropdownItem from '../DropdownMenu/DropdownItem/DropdownItem';
import {ReactComponent as ENFlag} from '../../Icons/ENG-Flag.svg';
import {ReactComponent as NLFlag} from '../../Icons/NED-Flag.svg';


function NavItemDD(props) {

   
    return (
        <li className="nav-itemDD">
            <a href={props.link} className="icon-buttonDD">
                {props.icon}
            </a>
            <div className="dropdownClass">
                <p><DropdownItem leftIcon={<ENFlag />} link="https://andras23102019.github.io/FCC-Personal-Portfolio/Andras_Romanek_CV_EN_.pdf"></DropdownItem></p>
                <p><DropdownItem leftIcon={<NLFlag />} link="https://andras23102019.github.io/FCC-Personal-Portfolio/Andras_Romanek_CV_NL.pdf"></DropdownItem></p>
                
                
            </div>     
            

        </li>
    )
}

export default NavItemDD;
