import React from 'react';
import './DropdownItem.css';

function DropdownItem(props) {
    
    return (
        <a 
        href={props.link} 
        className="menu-item" 
        target="_blank"
        rel="noopener noreferrer">
            <span className="icon-button">{props.leftIcon}</span>
            {props.children} 
            <span className="icon-right">{props.rightIcon}</span>
        </a>
    );
}

export default DropdownItem;