import React from 'react';
import './App.css';
import 'font-awesome/css/font-awesome.min.css';
import Navbar from './Navbar/Navbar';
import NavItem from './Navbar/NavItem/NavItem';
import NavItemDD from './Navbar/NavItem/NavItemDD';
import {ReactComponent as PortfolioIcon} from './Icons/portfolio.svg';
import {ReactComponent as HomeIcon} from './Icons/home.svg';
import {ReactComponent as CVIcon} from './Icons/cv.svg';

function App() {

  return (
    
    <div className="App">
      <Navbar>
        <NavItemDD icon={<CVIcon />} />
        <NavItem icon={<PortfolioIcon />} link="#projects" />
        <NavItem icon={<HomeIcon />} link="#welcome-section" />
      </Navbar>
      <div 
         id="welcome-section" 
         className="welcome-section">
          <h1 className="intro-text">
            <p className="intro1">Hi, I'm Andras</p>
          </h1>
          <p className="secondary-intro">
            SalesForce Certified Administrator<br/>
            and React developer
          </p>
      </div>

      <section id="projects" className="project-section">
        <h2 className="project-section-header">Most recent project
        </h2>

          <div className="project-items">
            <a 
            id="crm-demo"
            className="project-tile"
            href="https://moby-dick-demo.web.app/"
            target="_blank"
            rel="noopener noreferrer">
              <p 
                className="project-title">CRM for commodities sales
              </p>
              <img 
                className="projectimg"
                src="https://andras23102019.github.io/FCC-Personal-Portfolio/CRM-preview.jpg"
                alt="CRM Client details view"></img>
            </a>
            
          </div>
  
      </section>


    {/* 
      <div id="navbar" className="navbar">
        <a href="https://andras23102019.github.io/FCC-Personal-Portfolio/AndrasRomanek-developer-CV.pdf"
        className="nav-link"
        target="_blank"
        rel="noopener noreferrer">CV</a>
        <a href="#projects" className="nav-link">Projects</a>
      </div>
*/}

      <div id="contactbar" className="contactbar">
        <h4 style={{textAlign:"center", marginTop:"auto", marginBottom:"auto"}}>Get in touch: </h4>
        <a 
          className="contact-links"
          href="https://linkedin.com/in/andrasromanek"
          target="_blank"
          rel="noopener noreferrer">
        <i className="fa fa-linkedin"></i>
        &nbsp;LinkedIn</a>

        <a 
          className="contact-links"
          id="profile-link" 
          href="mailto:romanekandras@gmail.com" 
          target="_blank"
          rel="noopener noreferrer"
          >
        <i className="fa fa-at"></i>
        &nbsp;Email</a>
        
      </div>
    </div>
  );
}

export default App;
